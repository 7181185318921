import React from 'react'
import { Col  } from 'react-bootstrap'

export default class ProductAmazonRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        products: this.props.products
    };
  }

  render() {
    const products = this.state.products.map((product, index) => {
      return <Col xs={4} md={3} lg={2} key={index}>
        <iframe
        title="iframe"
        height='240px'
        // width='160px'
        src={product['src']}>
        </iframe>
      </Col>
    })

    return (
      <>{products}</>
    );
  }
}