const dataBaseAmazonProducts = [
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08CT5DQK3&linkId=7de5982733801336761cb12371f0912b",
		"category": "cube",
		"type": "",
		"description": "fruits"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07G9Z3994&linkId=ce53162018d34c592d18454faa90be4b&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07RHXXRPK&linkId=8eaf2cbf415ed66c62b68b047a7c7fc3",
		"category": "clothes",
		"type": "hoodie",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07QWL4F63&linkId=383161dc560e92860f9be88c6431199b",
		"category": "cube",
		"description": "giant"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07ZRHSKXH&linkId=e10bda3b45c3cbd7c7c36292c3253ea7&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "socks",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B091FV6WNW&linkId=9e92a696f551c5b9ffa5777013c8e277",
		"category": "cube",
		"description": "pig"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS1=1&asins=B0828WSPK8&linkId=8c0892a090d6cd60fd881c16461e0ec7&bc1=FFFFFF&amp;lt1=_top&fc1=333333&lc1=0066C0&bg1=FFFFFF&f=ifr",
		"category": "cube",
		"description": ""
	},
    {
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B088KQBZWR&linkId=ae6517aa69017db7740b8ab9a915e6ea",
		"category": "clothes",
		"type": "mask",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B075HB2ZQZ&linkId=45dad8bc074910b1b3b40b9c1bdf8831",
		"category": "cube",
		"description": ""
	},

	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B091FSBZH1&linkId=33e3b4d57cbcf39504e6452ec7286c9a",
		"category": "cube",
		"description": "crazy"
	},
   {
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08JH5TY1B&linkId=27a2f9ba96c260a89a66a122fefe00be",
		"category": "clothes",
		"type": "mask",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B081T3F15F&linkId=ccce1f06ce3bde9661b13112f35b3e9d",
		"category": "accessories",
		"type": "skarf",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07ZNRB5TM&linkId=6f272877f11a217ff3f50a6bee8f3250",
		"category": "clothes",
  		"type": "hat",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07BR1G3TR&linkId=844210835e43d17d2cad07d66ec82050",
		"category": "cube",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B091FQSL4S&linkId=6370a0b489a7cd63a6a2bca7964c2a37",
		"category": "cube",
		"description": "crazy"
	},
    {
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07CBSC4MJ&linkId=cb6841c03027ca73e87c1850b9cd7838&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
  		"type": "cap",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B08NJPP5VK&linkId=6b37a7242b0db59a3a09262c86731d17&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "cube",
		"description": "applecube"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07PC3JH8X&linkId=3d80c75da727a971c888481f267c71cb&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "t-shirt",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B079J1TKTR&linkId=0b33901a7b0aafb8475ad76fec144427&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "cube",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B086WSPBX9&linkId=c9cd0b2e36f5bf71da973767921ede4c&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B015ZOFUUA&linkId=a79630a3e3b51fcaf7d3954700e8d985&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "t-shirt",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07BGYQCNM&linkId=877bf75d54934125c61e260817964233",
		"category": "cube",
		"description": "mirrorcube"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07Q1DGB4T&linkId=7ba7078b87328b7a087ce536b2b9b66b&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "hoodie",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07Z61YGLM&linkId=24d5a93901fb650f512d00bd5f2e9244&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "socks",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07Q22PMXV&linkId=27f90906e1d124c569af88f5e93a2dc8&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07D5KJ2LK&linkId=6225e70dac9f229e12d685b3ce30c249",
		"category": "cube",
		"description": "centreless"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B08GG368PW&linkId=0ca9763b262f0c55f208c14da9c24fa4&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07QWSBCJ6&linkId=ce35762d5a9453ce9432cf4d256d2dd8",
		"category": "cube",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B0784R4X61&linkId=0d8c35a3dcc2fabfacdaf23ccf2210aa&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "socks",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07YCFVYD4&linkId=24e47a00a230aaf73ae9c7487a30ad53",
		"category": "clothes",
 		 "type": "hat",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B08GG1VWSV&linkId=3d6eee24727f16dfd873cda3789bc673&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B0859ZMDRD&linkId=bf51af86b07eaa6d84f4a968cf2c8ed7&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "socks",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B06XSXF67Q&linkId=b8cd24a1ed5e6ac9db79fce8c32af961",
		"category": "cube",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B074KCTK85&linkId=efd85a069c18e54a4c1434a7ad09ee3d&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS1=1&asins=B08GHX874G&linkId=34b57f648fc9649b983d1ee1204a935a&bc1=000000&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "clothes",
		"type": "socks",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07Z4Q76RM&linkId=d2561a4585ca5b2b9d151a9382196d91&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07R22YZHH&linkId=16d0885dcebe7b45c41bf1a0cdd904d0",
		"category": "cube",
		"description": "earth"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B082YRVQH7&linkId=c9d25ecfd5513aca4f5bd4e09fc699ea",
		"category": "clothes",
		"type": "hoodie",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS1=1&asins=B07T1NXJ4N&linkId=9478d7826ffefbf02bcde55aa6281358&bc1=FFFFFF&amp;lt1=_top&fc1=333333&lc1=0066C0&bg1=FFFFFF&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07L8BL3HG&linkId=567c69c5d423c4f3d4d5f1779a82714b",
		"category": "cube",
		"description": "earth"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?ref=tf_til&t=rubikscubek08-20&m=amazon&o=15&p=8&l=as1&IS2=1&asins=B07TV9Z77W&linkId=ec13c7531f29a0b2b7dbeea9d9e354d8&bc1=ffffff&amp;lt1=_blank&fc1=333333&lc1=0066c0&bg1=ffffff&f=ifr",
		"category": "accessories",
		"type": "packbag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07KZNF6SM&linkId=aff99fc68bcd30924d51dee3fd336ad7",
		"category": "cube",
		"description": "dollar"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=FFFFFF&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0895VXMPV&linkId=7bc2a4c46045a316b0454a9b4cc32143",
		"category": "clothes",
  		"type": "cap",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07Q25FJHL&linkId=5a34d0c4f29fe547f26dcc38a24e2e7b",
		"category": "clothes",
		"type": "hoodie",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0066EP8GA&linkId=2d55c2a35b823a437cabc3e90c9d0bf9",
		"category": "clothes",
		"type": "costume",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B00DWH3A9G&linkId=97a2b0b742b706205a7b5d6a04fe59d2",
		"category": "cube",
		"description": "x-cube"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08YYFTHL3&linkId=96795a117d6e9e12ce305ad2065b0273",
		"category": "clothes",
 		"type": "hat",
		"description": ""
	},
	
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07ZNQ4W7B&linkId=147b4f7e2c186265530b4bd400a0df65",
		"category": "clothes",
		"type": "shirt",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07YF9MV76&linkId=f2b22f7b0e9eb18e0616075b4169f890",
		"category": "clothes",
		"type": "mask",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08T66RKV1&linkId=55d19af828a480ee60c2033bd85a8a5e",
		"category": "clothes",
		"type": "mask",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07L8BR7GZ&linkId=ec62594be2ea2f85dbb00eec8828df81",
		"category": "cube",
		"description": "earth"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B085G7Q31P&linkId=974d94166fc19255cf4027664dd79c8a",
		"category": "clothes",
		"type": "tie",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08XWBFFSP&linkId=b561906590967cf31639a040756b0883",
		"category": "clothes",
		"type": "apron",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07R9NN344&linkId=ad9a64ea8c7acc14a9fa91c4ec2830fe",
		"category": "clothes",
		"type": "socks",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B010W5F4L2&linkId=f880345443ec0bb01ad73524132daeef",
		"category": "cube",
		"description": "paint"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B081DHLSG2&linkId=512d826df6b1138cddf312f544ddc9b9",
		"category": "accessories",
		"type": "skarf",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08YCW8VB1&linkId=073e432de0132a1d9503b2ef925566b7",
		"category": "clothes",
		"type": "mask",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08LL5S6GS&linkId=05dad75fab70048c9be249a0c5a5c437",
		"category": "clothes",
		"type": "t-shirt",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07YXLJKV6&linkId=31a32261076504d43914c8c92e98a72c",
		"category": "clothes",
		"type": "pants",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B01L03H64I&linkId=212782646bfdee49fb068687181f1c87",
		"category": "cube",
		"description": "rainbow"
	},
    {
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B01ESQO84U&linkId=21b9ebf7027a0fae2d2a933feca6bfdb",
		"category": "accessories",
		"type": "tie",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07QFKRQYM&linkId=d06644ae50e7ef3395ae29cd7ccb745e",
		"category": "accessories",
		"type": "bag",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B079HVFZQK&linkId=3e60425da8159af669a091d6dcab7e4c",
		"category": "cube",
		"description": "megaminx"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B087CRW6HP&linkId=d4a057aac952e2a0bd90dcf0fdc693cb",
		"category": "clothes",
  		"type": "cap",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07VCRCM6T&linkId=67eac7f19a5caeec26dc285410220296",
		"category": "cube",
		"description": "set"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07LCGKPQC&linkId=03d6c73c7a97ccc1de2f75c0f7b2902a",
		"category": "tableware",
		"type": "cup",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08729XQCC&linkId=52f8829fda5992dfee9374c1a6de2488",
		"category": "cube",
		"description": "windmill mirror"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07R3HC2TH&linkId=4dc51a6c49cc5f21fee3ae983d1f1fd2",
		"category": "tableware",
		"type": "cup",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B008C2KAGW&linkId=6c7acda5dabb297a5665dbf60ebff659",
		"category": "cube",
		"description": "other"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B088D61T5R&linkId=57f919b8d07b34baee05d2296d4d7926",
		"category": "home",
		"type": "pillow",
		"description": ""
	},

	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08126WX59&linkId=f82520a7c27e4b04475c8806fe6c6da9",
		"category": "home",
		"type": "pillow",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08P1NYKSB&linkId=ea4d757e3e1ab7426df1b1cfa147d4ae",
		"category": "home",
		"type": "beddingset",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07MXNG7DS&linkId=01666a7c5d395ba90839e4a85bec00fc",
		"category": "home",
		"type": "pillow",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B015GPB2NW&linkId=d36600a78884b727f0a02a445ce9a115",
		"category": "home",
		"type": "pouf",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B085L41G4J&linkId=9493faa4ffe15821ebf59fe389126a6f",
		"category": "cube",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B010W5EPZ8&linkId=4a4543f191a1f7caefa70f8c8dd1127a",
		"category": "cube",
		"description": "paint"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0108OINLK&linkId=c75b50db64dc2323074fc00937bed55a",
		"category": "cube",
		"description": "Van Gogh"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B010W5F610&linkId=2bdc675f175f71d58b0a06e04bc3f9f2",
		"category": "cube",
		"description": "Rembrandt"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07P6X3CTH&linkId=54b1a29c20862f8b80c6a0a1b07f9255",
		"category": "cube",
		"description": "set"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B085LZZ52X&linkId=095bbfca29c97f460637563f7c6ce083",
		"category": "cube",
		"description": "1*1"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B01MD030VB&linkId=54231a960a01caa8c9d7907997d96f97",
		"category": "cube",
		"description": "robot"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08XVSWL6K&linkId=56436557924b77d58c230756ee94524b",
		"category": "cube",
		"description": "other"
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B07LCGCY72&linkId=60f11e07544d31b12093c448c1e61957",
		"category": "tableware",
		"type": "cup",
		"description": ""
	},
	{
		"src": "//rcm-na.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=rubikscubek08-20&language=en_CA&o=15&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08FYC9QH4&linkId=81f2d6d048754e9933f92c605b863b0f",
		"category": "cube",
		"description": "set"
	},

]
export default dataBaseAmazonProducts;


