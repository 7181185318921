import React from 'react'
import dataBaseAmazonProducts from './dataBaseAmazonProducts'
import ProductAmazonRender from "../AmazoneAdBlocks/ProductAmazonRender"
import {Container, Row  } from 'react-bootstrap'

class BlockAmazonProducts extends React.Component {
  render() {
    const DATA = dataBaseAmazonProducts;
    return (<div>
      <Container style={{paddingTop: 30, paddingBottom: 30, display: 'block'}}>
        <Row>
          <ProductAmazonRender products={DATA} />
        </Row>
      </Container>
    </div>
    )
  }
}
export default BlockAmazonProducts;