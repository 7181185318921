import React from "react"
// import { Link } from "gatsby"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import LayoutTitle from "../components/Layouts/LayoutTitle/LayoutTitle"
import BlockAmazonProducts from "../components/AmazoneAdBlocks/BlockAmazonProducts"

const IndexPage = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LayoutTitle><h1 >{t('goods.h1')}</h1></LayoutTitle>
        <BlockAmazonProducts />
    </div>
  )
}
export default IndexPage